import * as React from "react"
import { Link } from 'gatsby'
import styled from "styled-components"
import Layout from '../../components/Layout'


const Links = styled.ul`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  li {
    margin-bottom: 1rem;
  }
`


const WorkIndexPage = () => {
  return (
    <Layout pageTitle="Work with me">
      <Links>
        <li>
          <h3>
            <Link to="/work/resume">
              Resume
            </Link>
          </h3>
          <p>
            You know, the stuff I've done to pay the bills
          </p>
        </li>
        <li>
          <h3>
            <Link to="/work/megatic">
              MegaTic
            </Link>
          </h3>
          <p>TicTacToe. Only more so.</p>
        </li>
      </Links>
    </Layout>
  )
}


export default WorkIndexPage
